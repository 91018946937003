import React from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import { Link } from "gatsby";
import BlueArrow from "../../resources/img/ui/blue-arrow-right.svg";
import WhiteArrow from "../../resources/img/ui/white-arrow-right.svg";

const LinkStyled = styled(Link)`
    text-decoration: none;
    color: ${props => props.blue === "true" ? colors.blue : colors.white};
    border: 1px solid ${props => props.blue === "true" ? colors.blue : colors.white};
    width: ${props => props.large ? '215px' : '194px'};
    height: 43px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ButtonStyled = styled.button`
    color: ${props => props.blue === "true" ? colors.blue : colors.white};
    border: 1px solid ${props => props.blue === "true" ? colors.blue : colors.white};
    width: ${props => props.large ? '215px' : '194px'};
    height: 43px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    outline: none;
    cursor: pointer;
    padding: 0;
`

const Text = styled.div`
    ${fonts.avantGardeMedium};
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    padding-left: 21px;
    padding-top: 17px;
    padding-bottom: 14px;
`;

const ArrowBox = styled.div`
    padding-left: 15.5px;
    padding-right: 15px;
    border-left: 1px solid ${props => props.blue === "true" ? colors.blue : colors.white};
    display: flex;
    align-items: center;
    height: 43px;
    img {
      height: 12px;
      width: 13px;
    }
`;

const Button = (props) => {
  const { link, children, blue, large, } = props
  return (
    <React.Fragment>
      {link ?
        <LinkStyled to={link} blue={blue ? "true" : "false"} large={large}>
          <Text>{children}</Text>
          <ArrowBox blue={blue ? "true" : "false"}>
            <img src={blue ? BlueArrow : WhiteArrow} alt={"Arrow "}/>
          </ArrowBox>
        </LinkStyled>
        :
        <ButtonStyled blue={blue ? "true" : "false"} large={large} >
          <Text>{children}</Text>
          <ArrowBox blue={blue ? "true" : "false"}>
            <img src={blue ? BlueArrow : WhiteArrow} alt={"Arrow "}/>
          </ArrowBox>
        </ButtonStyled>
      }
    </React.Fragment>
  )
}

export default Button
